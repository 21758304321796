import * as types from "./ActionTypes.js";
import axios from "axios";

// ...............isLoading state

export const getProductsLoading = () => {
  return {
    type: types.GET_PRODUCTS_REQUEST,
  };
};

// .................................

// ...................Success state

export const getProductsSuccess = (payload) => {
  return {
    type: types.GET_PRODUCTS_SUCCESS,
    payload,
  };
};
// .................................

// .................isError state

export const getProductsFailure = () => {
  return {
    type: types.GET_PRODUCTS_FAILURE,
  };
};
// .......................

// api call....Success/error/loading...........

export const resetProductsDetailsState = () => async (dispatch) => {
  dispatch({ type: "RESET_PRODUCTS_DETAILS" });
};

export const clearProductsstate = () => async (dispatch) => {
  dispatch({ type: types.GET_STATE_CLEAR });
};

export const getProducts = (params) => async (dispatch) => {
  dispatch(getProductsLoading());

  try {
    const response = await makeRequest(params);

    console.log("products:",response);

    if (response.data.status === 0) {
      dispatch(getProductsSuccess([]));
    }
     else  {
      console.log("store data", response.data);
      dispatch({ type: types.GET_TOTAL, payload: response.data.data.total });
      dispatch(getProductsSuccess(response.data.data.data));
      // dispatch({
      //   type: types.GET_PRODUCTS_SUCCESS,
      //   pagenumber: response.data.data.current_page
      //   payload: response.data.data.data,
      // });
      dispatch({
        type: types.GET_PRODUCTS_HEADING,
        payload: response.data.heading,
      });
    }
  } catch (error) {
    dispatch(getProductsFailure());
  }
};

async function makeRequest(params) {
  const maxRetryAttempts = 3;
  let retryAttempts = 0;

  async function sendRequest() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/fetchProducts`,
        params.params
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        if (retryAttempts < maxRetryAttempts) {
          const { retryAfter } = error.response.headers;

          await delay(retryAfter * 2000);
          // await delay(Math.pow(2, retryAttempts) * retryAfter * 1000);

          retryAttempts++;
          return sendRequest(); // Retry the API request
        } else {
          throw new Error("Maximum retry attempts reached");
        }
      } else {
        throw error;
      }
    }
  }

  return sendRequest();
}

async function delay(duration) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

//product details

export const getProductsDetailSuccess = (payload) => {
  return {
    type: types.GET_PRODUCTSDETAIL_SUCCESS,
    payload,
  };
};

export const getProductsDetail = (params) => async (dispatch) => {
  dispatch(getProductsLoading());
  console.log("api is hit", params.params);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/productdetails`,
      params.params
    );

    console.log("product detail response", response.data);

    if (response.data.status === 0) {
      dispatch(getProductsFailure());
    } else {
      dispatch(getProductsDetailSuccess(response.data));
    }
  } catch (error) {
    dispatch(getProductsFailure());
  }
};

//update wishlist

export const updateWishlist = (params) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_WISHLIST_SUCCESS, payload: params });
  } catch (err) {
    // dispatch(getProductsFailure())
  }
};

export const getNavdata = () => async (dispatch) => {
  try {
    console.log("getting nav data");
    const response = await axios.get(
      `${process.env.REACT_APP_API}/fetchDrawerCategories`
    );
    console.log("nav data", response.data.data);
    dispatch({ type: types.GET_NAVDATA_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch(getProductsFailure());
  }
};


