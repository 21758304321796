import {
  Box,
  Flex,
  Text,
  IconButton,
  MenuItem,
  HStack,
  Menu,
  MenuButton,
  Stack,
  VStack,
  MenuList,
  // Collapse,
  Icon,
  Tag,
  Badge,
  // Link,
  useColorModeValue,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Input,
  Center,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  CloseButton,
  Image,
  Show, // eslint-disable-next-line
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  HamburgerIcon,
  CloseIcon,
  ArrowBackIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import { CiUser, CiHeart, CiSearch } from "react-icons/ci";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsHandbag } from "react-icons/bs";
// import axios from "axios";
import CardList from "./Card";
// import { data } from "../Constants/navdata";
import { getVirtualTryOnLimitValue, login } from "../Redux/AuthReducer/Action";
// import { setTopBannerImg } from "../Redux/NavBarhelperdataslice";

const Navbar2 = ({ showBackIcon = false }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { isOpen, onOpen, onClose, onToggle, isProfileOpen } = useDisclosure();
  const navigate = useNavigate();
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  const closeButtonRef = useRef(null);

  // const [type, setType] = useState([]);
  const { isAuth, userData, virtualTryOnLimit } = useSelector(
    (store) => store.AuthReducer
  );
  const { NavData } = useSelector((store) => store.AppReducer);
  // const { WishList } = useSelector((store) => store.WishListReducer);
  // const { Cart } = useSelector((store) => store.CartReducer);

  const [isNoticeFlexOpen, setIsNoticeFlexOpen] = useState(true);

  const [navdata, setNavData] = useState([]);
  const [navCatdata, setCatNavData] = useState([]);
  const [navSubCatdata, setSubCatNavData] = useState([]);

  const searchRef = useRef();
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSubCatDrawerOpen, setIsSubCatDrawerOpen] = useState(false);
  const [isInnerSubCatDrawerOpen, setInnerIsSubCatDrawerOpen] = useState(false);
  const [heading, setHeading] = useState({
    subNav: "",
    innerSubNav: "",
  });
  const [Topbannerimg, setTopbannerimg] = useState("");
  const [cartlength, setCartlength] = useState(0);
  const [wishlistlength, setWishlistlength] = useState(0);
  const datatemp = useSelector((store) => store.NavBarhelperdata);

  // console.log("datatemp", datatemp);

  useEffect(() => {
    setTopbannerimg(datatemp.topbannerimg);
    setCartlength(datatemp.cartcount);
    setWishlistlength(datatemp.wishlistcount);
  }, [datatemp]);

  useEffect(() => {
    console.log("NavData in navbar", NavData);
    setNavData(NavData);
    //eslint-disable-next-line
  }, [NavData]);
  useEffect(() => {
    dispatch(getVirtualTryOnLimitValue(userData.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //debugger;
    const params = new URLSearchParams(location.search);
    const type = params.get("type");
    // eslint-disable-next-line
    const q = params.get("q");
    // console.log("after while rendering:", q);
    setSelectedCategory(type);
  }, [location.search]);

  const handleKeyDown = debounce((e) => {
    const trimmedValue = searchRef.current.value.trim();
    const searchParams = new URLSearchParams(location.search);

    if (trimmedValue) {
      searchParams.set("q", trimmedValue);

      navigate(`/store?q=${trimmedValue}`);
      // window.location.href="/store?q="+trimmedValue;
    } else {
      searchParams.delete("q");
      navigate(`/store?${searchParams.toString()}`);
    }
  }, 1000);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("type", categoryId);
    navigate(`/store?${searchParams}`);
  };
  const handleCloseNoticeFlex = () => {
    setIsNoticeFlexOpen(false);
  };

  /////closing of drawer/////
  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    if (closeButtonRef.current) {
      closeButtonRef.current.style.backgroundColor = "white"; // Change to the desired background color
    }
  };

  const onSubCatDrawerClose = () => {
    setIsSubCatDrawerOpen(false);
  };
  const onInnerSubCatDrawerClose = () => {
    setInnerIsSubCatDrawerOpen(false);
  };

  const onSubCatDrawerBack = () => {
    setIsSubCatDrawerOpen(false);
    setIsDrawerOpen(true);
    setCatNavData([]);
  };

  const onInnerSubCatDrawerBack = () => {
    setInnerIsSubCatDrawerOpen(false);
    setIsSubCatDrawerOpen(true);
    setIsDrawerOpen(false);
    setSubCatNavData([]);
  };

  const onDrawerOpen = () => {
    // setTimeout(() => {
    //   setIsDrawerOpen(true);
    // }, 1000);
    setIsDrawerOpen(true);
  };

  const onSubCatDrawerOpen = () => {
    setIsSubCatDrawerOpen(true);
  };
  const onInnerSubCatDrawerOpen = () => {
    setInnerIsSubCatDrawerOpen(true);
  };

  const handleCategoryCardClick = (item) => {
    onDrawerClose();
    setIsSubCatDrawerOpen(true);
    setCatNavData(item.subCategories);
    setHeading({ ...heading, subNav: item.category_name });
  };

  const handleSubCategoryCardClick = (item) => {
    onSubCatDrawerClose();
    setInnerIsSubCatDrawerOpen(true);
    // console.log("cards", item.innersubcategories);
    setSubCatNavData(item.innersubcategories);
    setHeading({ ...heading, innerSubNav: item.subcategory_name });
  };

  return (
    <Box>
      <Show breakpoint="(min-width:500px)">
        <>
          {isNoticeFlexOpen && Topbannerimg !== "" && (
            <Flex color="white" bg="black">
              <Center flex="1" p={2}>
                <Image src={Topbannerimg} />
              </Center>
              <IconButton
                aria-label="Close"
                icon={<CloseIcon />}
                mr={3}
                my={"auto"}
                onClick={handleCloseNoticeFlex}
                colorScheme="transaparent"
                size="xs"
              />
            </Flex>
          )}
        </>
        <Flex flexDirection={"row"}>
          <Box p="4" display={"inline-flex"} flex={0.4}>
            <IconButton
              onClick={
                navSubCatdata.length > 0
                  ? onInnerSubCatDrawerOpen
                  : navCatdata.length > 0
                  ? onSubCatDrawerOpen
                  : onDrawerOpen
              }
              icon={<HamburgerIcon w={7} h={7} />}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
              // size="lg"
            />
            <Image
              src={"https://kk-logo.boxinallsoftech.com/logo.png"}
              onClick={() => navigate("/")}
              cursor={"pointer"}
              // backgroundColor={'red'}
              alt="Logo"
              w={"45px"}
            />
            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav
                // type={type}
                cardType={"catNav"}
                selectedCategory={selectedCategory}
                linkColor={linkColor}
                linkHoverColor={linkHoverColor}
                popoverContentBgColor={popoverContentBgColor}
                handleCategoryClick={(id) => {
                  handleCategoryClick(id);
                }}
                navdata={navdata}
                isDrawerOpen={isDrawerOpen}
                onDrawerClose={onDrawerClose}
                closeButtonRef={closeButtonRef}
                handleCategoryCard={handleCategoryCardClick}
              />
              <DesktopSubCatNav
                // type={type}
                heading={heading.subNav}
                cardType={"subCatNav"}
                navdata={navCatdata}
                selectedCategory={selectedCategory}
                linkColor={linkColor}
                linkHoverColor={linkHoverColor}
                popoverContentBgColor={popoverContentBgColor}
                handleCategoryClick={(id) => {
                  handleCategoryClick(id);
                }}
                isSubCatDrawerOpen={isSubCatDrawerOpen}
                onSubCatDrawerClose={onSubCatDrawerClose}
                onSubCatDrawerBack={onSubCatDrawerBack}
                handleCategoryCard={handleSubCategoryCardClick}
              />
              <DesktopInnerSubCatNav
                // type={type}
                heading={heading.innerSubNav}
                cardType={"innerSubCatNav"}
                navdata={navSubCatdata}
                selectedCategory={selectedCategory}
                linkColor={linkColor}
                linkHoverColor={linkHoverColor}
                popoverContentBgColor={popoverContentBgColor}
                handleCategoryClick={(id) => {
                  handleCategoryClick(id);
                }}
                isInnerSubCatDrawerOpen={isInnerSubCatDrawerOpen}
                onInnerSubCatDrawerClose={onInnerSubCatDrawerClose}
                onInnerSubCatDrawerBack={onInnerSubCatDrawerBack}
                handleCategoryCard={handleSubCategoryCardClick}
              />
            </Flex>
          </Box>
          {/* <Spacer /> */}

          <Flex
            flex={0.2}
            //  backgroundColor={'blue'}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Show
              breakpoint="(min-width: 500px)"
              style={{ alignItems: "center" }}
            >
              <Box display="flex" alignItems="center">
                <Image
                  src={"https://kk-logo.boxinallsoftech.com/98Degree.png"}
                  w={"125px"}
                  _hover={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                />
              </Box>
            </Show>
          </Flex>

          {/* <Spacer /> */}
          <Box p="4" flex={0.4} display="flex" alignItems="center">
            <Stack
              flex={{ base: 1, md: 1, lg: 1 }}
              justify={""}
              direction={"row"}
              spacing={6}
            >
              <Show breakpoint="(min-width: 500px)">
                <InputGroup
                  variant="filled"
                  maxWidth={{ base: "150px", sm: "250px", md: "300px" }}
                >
                  <InputLeftElement
                    fontWeight={"500"}
                    fontSize="14px"
                    color={"#696e79"}
                    children={<SearchIcon />}
                  />
                  <Input
                    fontWeight={"400"}
                    fontSize="14px"
                    color={"#696e79"}
                    type={"text"}
                    defaultValue={
                      //eslint-disable-next-line
                      new URLSearchParams(location.search).get("q") !== null || //eslint-disable-next-line
                      new URLSearchParams(location.search).get("q") == "" //eslint-disable-next-line
                        ? new URLSearchParams(location.search).get("q")
                        : ""
                    }
                    textColor="#696e79"
                    focusBorderColor="grey"
                    border={"1px solid"}
                    ref={searchRef}
                    onChange={handleKeyDown}
                    placeholder="Search for products and more"
                  />
                </InputGroup>
              </Show>
              <Show breakpoint="(max-width: 500px)">
                <VStack spacing={"3px"} onClick={onOpen}>
                  <Icon as={CiSearch} fontSize="lg" />
                  <Text fontSize={"12px"} fontWeight={"500"} color={"#282c3f"}>
                    Search
                  </Text>
                </VStack>
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalBody>
                      <InputGroup
                        variant="filled"
                        // maxWidth={{ base: "150px", sm: "250px", md: "300px" }}
                      >
                        <InputLeftElement
                          fontWeight={"500"}
                          fontSize="14px"
                          color={"#696e79"}
                          children={<SearchIcon />}
                        />
                        <Input
                          fontWeight={"400"}
                          fontSize="14px"
                          color={"#696e79"}
                          type={"text"}
                          defaultValue={
                            //eslint-disable-next-line
                            new URLSearchParams(location.search).get("q") !==
                              null || //eslint-disable-next-line
                            new URLSearchParams(location.search).get("q") == "" //eslint-disable-next-line
                              ? new URLSearchParams(location.search).get("q")
                              : ""
                          }
                          textColor="#696e79"
                          focusBorderColor="grey"
                          border={"1px solid"}
                          ref={searchRef}
                          onChange={handleKeyDown}
                          placeholder="Search for products and more"
                        />
                      </InputGroup>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Show>

              <Box>
                <HStack spacing={"30px"}>
                  {/* profile  */}
                  {/*  */}
                  {/*  */}
                  {/*  */}
                  {/*  */}

                  <VStack spacing={"3px"}>
                    <Menu isProfileOpen={isProfileOpen}>
                      <MenuButton>
                        <VStack spacing={"3px"}>
                          <Icon as={CiUser} fontSize="lg" />
                          <Text
                            fontSize={"12px"}
                            fontWeight={"500"}
                            color={"#282c3f"}
                          >
                            Profile
                          </Text>
                        </VStack>
                      </MenuButton>
                      <MenuList onMouseEnter={onOpen} onMouseLeave={onClose}>
                        <MenuItem>
                          <VStack spacing={2} alignItems="flex-start">
                            <Text
                              fontSize={"14px"}
                              color="#333333"
                              fontWeight={"500"}
                            >
                              Welcome {isAuth ? userData.name : ""}
                            </Text>
                            <Text fontSize={"14px"} color="#333333">
                              {isAuth ? userData.mobile : ""}
                            </Text>

                            {!isAuth ? (
                              <Tag
                                variant={"outline"}
                                colorScheme="pink"
                                size={"md"}
                                fontSize={"14px"}
                                onClick={() => {
                                  if (!isAuth) {
                                    navigate("/login");
                                  }
                                }}
                              >
                                LOGIN
                              </Tag>
                            ) : (
                              <></>
                            )}
                          </VStack>
                        </MenuItem>
                        <hr />
                        <MenuItem fontSize={"13px"}>
                          Virtual TryOn Credits{" "}
                          <span
                            style={{
                              color: "blue",
                              marginLeft: "5px",
                              fontSize: "16px",
                            }}
                          >
                            {virtualTryOnLimit}
                          </span>
                        </MenuItem>

                        <MenuItem
                          fontSize={"13px"}
                          onClick={() => navigate("/profileSettings")}
                        >
                          Profile Settings
                        </MenuItem>

                        <MenuItem
                          fontSize={"13px"}
                          onClick={() => navigate("/orders")}
                        >
                          Orders
                        </MenuItem>
                        <MenuItem
                          fontSize={"13px"}
                          onClick={() => navigate("/contact")}
                        >
                          Contact Us
                        </MenuItem>
                        {isAuth ? (
                          <MenuItem
                            fontSize={"13px"}
                            onClick={() =>
                              dispatch(login("logout"), navigate("/login"))
                            }
                          >
                            Sign Out
                          </MenuItem>
                        ) : (
                          <></>
                        )}
                      </MenuList>
                    </Menu>
                  </VStack>

                  {/* wishlist */}
                  {/*  */}
                  {/*  */}
                  {/*  */}
                  {/*  */}
                  <VStack
                    spacing={"3px"}
                    // display={{ base: "none", md: "inline-flex" }}
                    position="relative"
                    onClick={() => navigate("/wishlist")}
                    _hover={{ cursor: "pointer" }}
                  >
                    <Icon as={CiHeart} fontSize="xl" />
                    <Badge
                      colorScheme="red"
                      fontSize="10px"
                      position="absolute"
                      top="-5px"
                      right="-5px"
                      zIndex="1"
                      borderRadius={"50%"}
                    >
                      {/* {WishList.length} */}
                      {wishlistlength}
                    </Badge>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"500"}
                      color={"#282c3f"}
                    >
                      Wishlist
                    </Text>
                  </VStack>

                  {/* bag */}
                  {/*  */}
                  {/*  */}
                  {/*  */}
                  <VStack
                    spacing={"3px"}
                    onClick={() => navigate(`/cart?pageType=${1}`)}
                    // display={{ base: "none", md: "inline-flex" }}
                    position="relative"
                    _hover={{ cursor: "pointer" }}
                  >
                    <Icon as={BsHandbag} fontSize="lg" />
                    <Badge
                      colorScheme="red"
                      fontSize="10px"
                      position="absolute"
                      top="-5px"
                      right="-5px"
                      zIndex="1"
                      borderRadius={"50%"}
                    >
                      {/* {Cart.length} */}
                      {cartlength}
                    </Badge>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"500"}
                      color={"#282c3f"}
                    >
                      Bag
                    </Text>
                  </VStack>
                </HStack>
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Show>
      {/* Desktop nav */}

      {/* flex code navigation pallav working code */}
      <Show breakpoint="(max-width:500px)">
        <Flex position={"fixed"} zIndex={"10"} background={"white"} w={"100%"}>
          <Box
            paddingTop="4"
            paddingBottom="4"
            paddingRight="4"
            display={"inline-flex"}
          >
            {showBackIcon ? (
              <IconButton
                onClick={() => navigate("/")}
                icon={<ArrowBackIcon w={6} h={6} />}
                variant={"ghost"}
                aria-label={"Toggle Navigation"}
                // size="lg"
              />
            ) : null}

            <IconButton
              onClick={
                navSubCatdata.length > 0
                  ? onInnerSubCatDrawerOpen
                  : navCatdata.length > 0
                  ? onSubCatDrawerOpen
                  : onDrawerOpen
              }
              icon={<HamburgerIcon w={7} h={7} />}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
            <Image
              src={"https://kk-logo.boxinallsoftech.com/fullLogo.png"}
              onClick={() => navigate("/")}
              cursor={"pointer"}
              alt="Logo"
              w={"100px"}
            />
            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav
                // type={type}
                cardType={"catNav"}
                selectedCategory={selectedCategory}
                linkColor={linkColor}
                linkHoverColor={linkHoverColor}
                popoverContentBgColor={popoverContentBgColor}
                handleCategoryClick={(id) => {
                  handleCategoryClick(id);
                }}
                navdata={navdata}
                isDrawerOpen={isDrawerOpen}
                onDrawerClose={onDrawerClose}
                handleCategoryCard={handleCategoryCardClick}
              />
              <DesktopSubCatNav
                // type={type}
                heading={heading.subNav}
                cardType={"subCatNav"}
                navdata={navCatdata}
                selectedCategory={selectedCategory}
                linkColor={linkColor}
                linkHoverColor={linkHoverColor}
                popoverContentBgColor={popoverContentBgColor}
                handleCategoryClick={(id) => {
                  handleCategoryClick(id);
                }}
                isSubCatDrawerOpen={isSubCatDrawerOpen}
                onSubCatDrawerClose={onSubCatDrawerClose}
                onSubCatDrawerBack={onSubCatDrawerBack}
                handleCategoryCard={handleSubCategoryCardClick}
              />
              <DesktopInnerSubCatNav
                // type={type}
                heading={heading.innerSubNav}
                cardType={"innerSubCatNav"}
                navdata={navSubCatdata}
                selectedCategory={selectedCategory}
                linkColor={linkColor}
                linkHoverColor={linkHoverColor}
                popoverContentBgColor={popoverContentBgColor}
                handleCategoryClick={(id) => {
                  handleCategoryClick(id);
                }}
                isInnerSubCatDrawerOpen={isInnerSubCatDrawerOpen}
                onInnerSubCatDrawerClose={onInnerSubCatDrawerClose}
                onInnerSubCatDrawerBack={onInnerSubCatDrawerBack}
                handleCategoryCard={handleSubCategoryCardClick}
              />
            </Flex>
          </Box>
          <Box w={"10%"}></Box>
          <Box p="5">
            <Stack
              flex={{ base: 1, md: 1, lg: 1 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={"12px"}
            >
              <VStack spacing={"3px"} onClick={onOpen}>
                <Icon as={CiSearch} fontSize="25px" />
              </VStack>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalBody>
                    <InputGroup
                      variant="filled"
                      // maxWidth={{ base: "150px", sm: "250px", md: "300px" }}
                    >
                      <InputLeftElement
                        fontWeight={"500"}
                        fontSize="14px"
                        color={"#696e79"}
                        children={<SearchIcon />}
                      />
                      <Input
                        fontWeight={"400"}
                        fontSize="14px"
                        color={"#696e79"}
                        type={"text"}
                        defaultValue={
                          //eslint-disable-next-line
                          new URLSearchParams(location.search).get("q") !==
                            null || //eslint-disable-next-line
                          new URLSearchParams(location.search).get("q") == "" //eslint-disable-next-line
                            ? new URLSearchParams(location.search).get("q")
                            : ""
                        }
                        textColor="#696e79"
                        focusBorderColor="grey"
                        border={"1px solid"}
                        ref={searchRef}
                        onChange={handleKeyDown}
                        placeholder="Search for products and more"
                      />
                    </InputGroup>
                  </ModalBody>
                </ModalContent>
              </Modal>
              <Box>
                <HStack spacing={"12px"}>
                  {/* wishlist */}
                  {/*  */}
                  {/*  */}
                  {/*  */}
                  <VStack
                    spacing={"3px"}
                    // display={{ base: "none", md: "inline-flex" }}
                    position="relative"
                    onClick={() => navigate("/wishlist")}
                    _hover={{ cursor: "pointer" }}
                  >
                    <Icon as={CiHeart} fontSize="25px" />
                    <Badge
                      // colorScheme="red"
                      bg={"red"}
                      color={"white"}
                      fontSize="10px"
                      position="absolute"
                      top="-5px"
                      right="-5px"
                      zIndex="1"
                      borderRadius={"50%"}
                    >
                      {/* {WishList.length} */}
                      {wishlistlength}
                    </Badge>
                  </VStack>

                  {/* cart */}
                  {/*  */}
                  {/*  */}
                  {/*  */}
                  <VStack
                    spacing={"3px"}
                    onClick={() => navigate(`/cart?pageType=${1}`)}
                    position="relative"
                    _hover={{ cursor: "pointer" }}
                  >
                    <Icon as={BsHandbag} fontSize="25px" />
                    <Badge
                      bg={"red"}
                      color={"white"}
                      fontSize="10px"
                      position="absolute"
                      top="-5px"
                      right="-5px"
                      zIndex="1"
                      borderRadius={"50%"}
                    >
                      {/* {Cart.length} */}
                      {cartlength}
                    </Badge>
                  </VStack>

                  <VStack
                    spacing={"3px"}
                    onClick={() => navigate("/profilesSettigsMob")}
                    position="relative"
                    _hover={{ cursor: "pointer" }}
                  >
                    <Icon as={CiUser} fontSize="25px" />
                  </VStack>
                </HStack>
              </Box>
            </Stack>
          </Box>
        </Flex>
        <Box h={70}></Box>
      </Show>
    </Box>
  );
};

const DesktopNav = ({
  selectedCategory,
  type,
  linkColor,
  linkHoverColor,
  handleCategoryClick,
  popoverContentBgColor,
  cardType,
  onDrawerClose,
  closeButtonRef,
  navdata,
  isDrawerOpen,
  handleCategoryCard,
}) => {
  return (
    <Drawer
      placement={"left"}
      onClose={onDrawerClose}
      isOpen={isDrawerOpen}
      size={"sm"}
      style={{
        transition: "transform 0.3s ease-in-out",
        transform: isDrawerOpen ? "translateX(0)" : "translateX(-100%)",
      }}
    >
      <DrawerOverlay />
      <DrawerContent overflowY="auto">
        <DrawerHeader>
          <Box w="100%" p={2} display="flex" alignItems="center">
            <CloseButton
              aria-label="Close drawer"
              onClick={onDrawerClose}
              size="xl"
              mr={2}
              bg="white"
              ref={closeButtonRef}
            />
          </Box>
        </DrawerHeader>
        <Box p={4} mt={5}>
          <CardList
            handleCategoryCard={handleCategoryCard}
            navdata={navdata}
            cardType={cardType}
            style={{ cursor: "pointer" }}
          />
        </Box>
      </DrawerContent>
    </Drawer>
  );
};

const DesktopSubCatNav = ({
  heading,
  selectedCategory,
  type,
  cardType,
  linkColor,
  linkHoverColor,
  handleCategoryClick,
  popoverContentBgColor,
  navdata,
  onSubCatDrawerClose,
  onSubCatDrawerBack,
  isSubCatDrawerOpen,
  handleCategoryCard,
}) => {
  return (
    <Drawer
      placement={"left"}
      onClose={onSubCatDrawerClose}
      isOpen={isSubCatDrawerOpen}
      size={"sm"}
    >
      <DrawerOverlay />
      <DrawerContent overflowY="auto">
        <DrawerHeader>
          <Box bg="gray.100" w="100%" p={2} display="flex" alignItems="center">
            <IconButton
              aria-label="Go back"
              icon={<ArrowBackIcon />}
              onClick={onSubCatDrawerBack}
              size="md"
              mr={2}
              colorScheme="gray"
            />
            <Center flex={0.8}>
              <Text textTransform={"uppercase"}>{heading}</Text>
            </Center>
          </Box>
        </DrawerHeader>
        <Box p={4} mt={5}>
          <CardList
            handleCategoryCard={handleCategoryCard}
            navdata={navdata}
            cardType={cardType}
          />
        </Box>
      </DrawerContent>
    </Drawer>
  );
};

const DesktopInnerSubCatNav = ({
  heading,
  selectedCategory,
  type,
  cardType,

  linkColor,
  linkHoverColor,
  handleCategoryClick,
  popoverContentBgColor,
  navdata,
  onInnerSubCatDrawerClose,
  onInnerSubCatDrawerBack,
  isInnerSubCatDrawerOpen,
  handleCategoryCard,
}) => {
  return (
    <Drawer
      placement={"left"}
      onClose={onInnerSubCatDrawerClose}
      isOpen={isInnerSubCatDrawerOpen}
      size={"sm"}
    >
      <DrawerOverlay />
      <DrawerContent overflowY="auto">
        <DrawerHeader>
          <Box bg="gray.100" w="100%" p={2} display="flex" alignItems="center">
            <IconButton
              aria-label="Go back"
              icon={<ArrowBackIcon />}
              onClick={onInnerSubCatDrawerBack}
              size="md"
              mr={2}
              colorScheme="gray"
            />
            <Center flex={0.8}>
              <Text textTransform={"uppercase"}>{heading}</Text>
            </Center>
          </Box>
        </DrawerHeader>
        <Box p={4} mt={5}>
          <CardList
            handleCategoryCard={handleCategoryCard}
            navdata={navdata}
            cardType={cardType}
            onClose={onInnerSubCatDrawerClose}
          />
        </Box>
      </DrawerContent>
    </Drawer>
  );
};

export default Navbar2;
