import {
    Box,
    Button,
    Center,
    Grid,
    Heading,
    HStack,
    Image,
    SimpleGrid,
    Link,
    StackDivider,
    Text,
    VStack,
    Spinner,
    Icon,
    Badge,
    InputGroup,
    Input,
    InputRightElement,
    Flex,
    Show,
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AccordionIcon,
    useMediaQuery,
  } from "@chakra-ui/react";
  import React, { useEffect, useState, useMemo } from "react";
  import { StarIcon } from "@chakra-ui/icons";
  import { AiOutlineHeart } from "react-icons/ai";
  import { useDispatch, useSelector } from "react-redux";
  import { useLocation, useNavigate, useParams } from "react-router-dom";
  import Footer from "../Components/Footer";
  // import Navbar from '../Components/Navbar'
  import { getProductsDetail } from "../Redux/AppReducer/Action";
  import { BsHandbag, BsTruck, BsFillHeartFill } from "react-icons/bs";
  import { GiClothes } from "react-icons/gi";
  import { BiPurchaseTag } from "react-icons/bi";
  import axios from "axios";
  // import SingleProductCom from "../Components/SingleProductCom";
  import { addToCart } from "../Redux/CartReducer/Action";
  import Navbar2 from "../Components/Navbar2";
  // import ImageGalleryZoom from '../Components/ImageGalleryZoom';
  import ImageModal from "../Components/ImageModal";
  import showCustomToast from "../Components/showCustomToast";
  // import { getWishList } from "../Redux/WishListReducer/Action";
  import ViewCoupanModal from "../Components/ViewCoupansModal";
  import SizeChartModal from "../Components/SizeChartModal.jsx";
  import { Carousel } from "react-responsive-carousel";
  import { getWishlistCountAsync } from "../Redux/NavBarhelperdataslice";
  import "react-responsive-carousel/lib/styles/carousel.min.css";
  import RelatedProduct from "../Components/RelatedProduct";
  import VirtualTryOn from "../Components/VirtualTryOn.jsx";

  const style = {
    hover: {
      transform: "scale(110%)",
      transition: "transform 1s, filter .20s ease-in-out",
      transformOrigin: "center center",
      filter: "brightness(95%)",
      cursor: "zoom-in",
    },

    style: {
      overflow: "hidden",
    },
  };

  const SingleProduct = () => {
    const dispatch = useDispatch();
    //  const pinInputRef=useRef("")
    const { id } = useParams();
    const [sizeRef, setSize] = useState("");
    const location = useLocation();
    //  const [searchParams]=useSearchParams()
    const [discountedPrice, setDiscountedPrice] = useState("");
    // const [description, setDescription] = useState("");
    const [productPrice, setProductPrice] = useState("");
    const [variation, setVariation] = useState("");
    const [discountPer, setDiscountPer] = useState("");
    const { Products, ProductsDetail, isLoading } = useSelector(
      (store) => store.AppReducer
    );
    const { carttoastMessage } = useSelector((store) => store.CartReducer);
    const { isAuth, userData } = useSelector((store) => store.AuthReducer);
    const [currentProduct, setCurrentProduct] = useState(ProductsDetail.data);
    const [relatedProduct, setRelatedProduct] = useState([]);
    const [coupon, setCoupon] = useState([]);
    const [productColor, setProductColor] = useState([]);
    const [inputPincode, setInputPincode] = useState([]);
    const [isPincodeVerified, setIsPincodeVerified] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [pincodeAvailbility, setPincodeAvailbility] = useState({
      status: 2, //no action
      message: "",
      data: "",
    });
    const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
    const {
      product_price = "",
      brand = "",
      category = "",
      discount = "",
      discounted_price,
      productimages = [],
      is_wishlist = "",
      rattings = "",
      variations = [],
      product_name = "",
      type = "",
    } = ProductsDetail.data || {};
    // useEffect(()=>{
    //   setproductImages(productimages);
    // },[ProductsDetail])
    //  const [iswishLoading, setIswishLoading] = useState(false);
    const [wishlistStatus, setWishlistStatus] = useState(
      Boolean(Number(is_wishlist))
    ); // State variable for wishlist status
    const [similarProducts, setSimilarProducts] = useState([]);
    const [imageClickedIndex, setImageClickedIndex] = useState(0);
    const [isImgModalOpen, setIsImgModalOpen] = useState(false);
    const [isCoupansModalOpen, setIsCoupansModalOpen] = useState(false);
    const [isSizeChartModalOpen, setIsSizeChartModalOpen] = useState(false);
    // eslint-disable-next-line
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isTryOnOpen, setIsTryOnOpen] = useState(false);
    // const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();

    const handleImgOpenModal = (index) => {
      setImageClickedIndex(index);
      setIsImgModalOpen(true);
    };

    const handleImgCloseModal = () => {
      setIsImgModalOpen(false);
    };

    //  const discount = (((product_price - discounted_price) / product_price) * 100).toFixed(0);
    //console.log("wishlistStatus data", wishlistStatus, is_wishlist);

    var rating;
    var count;
    if (rattings.length > 0) {
      rating = rattings[0].avg_rating;
      count = rattings[0].count;
    } else {
      rating = 0;
      count = 0;
    }
    const params = useMemo(
      () => ({
        product_id: id,
        user_id: userData.id,
      }),
      [id, userData.id]
    );
    //const discount = (((product_price - discounted_price) / product_price) * 100).toFixed(0);
    const handleWishlistClick = () => {
      if (isAuth) {
        //setIswishLoading(true);
        const apiURL = wishlistStatus
          ? `${process.env.REACT_APP_API}/removefromwishlist`
          : `${process.env.REACT_APP_API}/addtowishlist`;

        axios({
          method: "post",
          url: apiURL,
          data: params,
        })
          .then((res) => {
            // dispatch(getWishList(params));
            dispatch(getWishlistCountAsync(params));
            showCustomToast(res.data.message, "success");
            setWishlistStatus((prevStatus) => !prevStatus); // Toggle the wishlist status
          })
          .catch((err) => {
            showCustomToast("item already present in wishlist", "error");
          });
      } else {
        navigate("/login");
      }
    };

    useEffect(() => {
      if (carttoastMessage) {
        showCustomToast(carttoastMessage, "success");
      }
    }, [carttoastMessage]);

    useEffect(() => {
      const getProductParams = {
        params: params,
      };
      console.log("params", params);

      dispatch(getProductsDetail(getProductParams));
      //eslint-disable-next-line
    }, [id]);

    useEffect(() => {
      // Update wishlistStatus when is_wishlist value changes
      setWishlistStatus(Boolean(Number(is_wishlist)));
    }, [ProductsDetail, is_wishlist]);

    useEffect(() => {
      if (ProductsDetail.data) {
        //console.log("single product", ProductsDetail.data)
        setCurrentProduct(ProductsDetail.data);
        setRelatedProduct(ProductsDetail.related_products);
        setCoupon(ProductsDetail.coupons);
        setProductColor(ProductsDetail.colors);
        setDiscountedPrice(discounted_price);
        setProductPrice(product_price);
        setDiscountPer(discount);
        setProductImages(ProductsDetail.data.productimages);
      } else {
        setProductImages([]);
      }

      //eslint-disable-next-line
    }, [
      ProductsDetail.data,
      ProductsDetail.related_products,
      discounted_price,
      product_price,
      discount,
    ]);

    useEffect(() => {
      if (Products.length !== 0) {
        const newSimilarProducts = Products?.filter((el) => {
          return category === el.category && type === el.type && el.id !== +id;
        });
        setSimilarProducts(newSimilarProducts);
      }
    }, [
      location.search,
      id,
      Products.length,
      similarProducts.length,
      currentProduct,
      setSimilarProducts,
      Products,
      category,
      type,
    ]);

    const setsize = (size, el) => {
      setDiscountedPrice(el.discounted_variation_price);
      setProductPrice(el.price);

      //const discount = (((el.price - el.variation_discount) / el.price) * 100).toFixed(0);
      setDiscountPer(el.variation_discount);
      if (size === sizeRef) {
        setSize("");
        setVariation("");
      } else {
        setSize(size);
        setVariation(el.variation);
      }
    };

    const handleSendCart = () => {
      if (variation === "" && variations.length > 0) {
        showCustomToast("select size", "error");
        return;
      } else {
        const params = {
          product_id: currentProduct.id,
          user_id: userData.id,
          vendor_id: parseInt(currentProduct.vendor_id),
          product_name: currentProduct.product_name,
          qty: 1,
          price: discountedPrice,
          variation: variation,
          attribute: "",
          shipping_cost: "",
          tax: "",
          image: productimages[0].image_name,
          image_url: productimages[0].image_url,
        };
        const successCallback = (success) => {};
        dispatch(addToCart(params, successCallback));
      }
    };

    const handleOpenTryOn = () => {
      if (isAuth) {
        setIsTryOnOpen(true);
      } else {
        navigate("/signup", {
          state: `/fetchProductDetails/${id}`,
          replace: true,
        });
      }
    };

    const couponText = coupon.map((coupon, index) => {
      // console.log("Product color",productColor);
      const couponLines = coupon.split("\n");
      return couponLines.map((line, lineIndex) => (
        <Text
          key={`${index}-${lineIndex}`}
          color={"#282c3f"}
          fontSize="14px"
          as={lineIndex === 0 ? "b" : ""}
        >
          {lineIndex < 2 ? line : ""}
        </Text>
      ));
    });

    const couponCode = coupon.map((coupon, index) => {
      const couponLines = coupon.split("\n");
      return couponLines[2].substr(12);
    });

    const handlePincodeCheck = () => {
      if (inputPincode.length !== 6) {
        showCustomToast("Please Enter Valid Pincode!", "error");
        return;
      }
      if (pincodeAvailbility.status === 1 || pincodeAvailbility.status === 0) {
        setInputPincode("");
        setPincodeAvailbility({
          ...pincodeAvailbility,
          status: 2,
          message: "",
          data: "",
        });
        setIsPincodeVerified(!isPincodeVerified);
      } else if (inputPincode.length === 6 && pincodeAvailbility.status === 2) {
        const apiURL = `${process.env.REACT_APP_API}/checkpincodeserviceability`;
        axios({
          method: "post",
          url: apiURL,
          data: {
            pincode: inputPincode,
          },
        })
          .then((res) => {
            setIsPincodeVerified(!isPincodeVerified);
            if (res.data.status === 1) {
              setPincodeAvailbility({
                ...pincodeAvailbility,
                message: res.data.message,
                status: 1,
                data: res.data.data,
              });
            } else if (res.data.status === 0) {
              setPincodeAvailbility({
                ...pincodeAvailbility,
                message: res.data.message,
                status: 0,
                data: res.data.data,
              });
            }
          })
          .catch((err) => {});
      }
    };
    const handleCoupansModalClose = () => {
      setIsCoupansModalOpen(false);
    };

    const [processedDescription, setProcessedDescription] = useState("");
    const [description, setDescription] = useState("");
    const [size_description, setSize_description] = useState("");

    useEffect(() => {
      if (ProductsDetail?.data?.material_description) {
        const splitDescription =
          ProductsDetail.data.material_description.split("-/-");
        const processedDescriptionHTML = splitDescription.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));

        setProcessedDescription(processedDescriptionHTML);
      }

      if (ProductsDetail?.data?.description) {
        const splitDescription = ProductsDetail.data.description.split("\r\n");
        const processedDescriptionHTML = splitDescription.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));

        setDescription(processedDescriptionHTML);
      }

      if (ProductsDetail?.data?.size_description) {
        const splitDescription =
          ProductsDetail.data.size_description.split("\r\n");
        const processedDescriptionHTML = splitDescription.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));

        setSize_description(processedDescriptionHTML);
      }
    }, [ProductsDetail]);
    return (
      <>
        <Navbar2 />
        {/* ....................... */}
        {/* {(!ProductsDetail.data || ProductsDetail.data.length === 0)  && isLoading ? <Box */}
        {isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            z-index="9999"
          >
            <Spinner size="xl" color="pink.500" />
          </Box>
        ) : (
          <Box
            w={"full"}
            // p={"15px 30px"}
          >
            <Grid
              templateColumns={{
                base: "1",
                sm: "0% 100%",
                md: "55% 40%",
                lg: "55% 40%",
              }}
              gap={8}
              w="full"
            >
              <Show breakpoint="(min-width: 500px)">
                <Box>
                  <SimpleGrid
                    columns={2}
                    // spacing={2}
                    spacingX="2px"
                    spacingY="3px"
                    w="100%"
                  >
                    {productImages?.map((img, i) => {
                      return (
                        <Box style={style.style} w="full" key={i}>
                          <Image
                            _hover={style.hover}
                            src={img.image_url}
                            w="full"
                            onClick={() => handleImgOpenModal(i)}
                          />
                        </Box>
                      );
                    })}
                    {productImages.length !== 0 ? (
                      <ImageModal
                        isOpen={isImgModalOpen}
                        onClose={handleImgCloseModal}
                        productImages={productimages}
                        index={imageClickedIndex}
                      />
                    ) : (
                      <></>
                    )}
                  </SimpleGrid>
                </Box>
              </Show>
              <Show breakpoint="(max-width: 500px)">
                <Box>
                  <Carousel
                    showStatus={false}
                    showArrows={false}
                    infiniteLoop={false}
                    swipeable={true}
                    showThumbs={false}
                    autoPlay={false}
                    emulateTouch={true}
                  >
                    {productimages?.map((img, i) => {
                      return (
                        <Box position="relative" key={i}>
                          <Box
                            // position="relative"
                            style={i === currentImageIndex ? style.style : {}}
                            w="full"
                            key={i}
                            flex="0 0 auto"
                            display={{ base: "inline-flex", sm: "block" }}
                            onClick={() => handleImgOpenModal(i)}
                          >
                            <Image
                              _hover={style.hover}
                              src={img.image_url}
                              w="full"
                              // onClick={() => handleImgOpenModal(i)}
                            />
                          </Box>
                          <Flex
                            position="absolute"
                            top="5px"
                            right="5px"
                            flexDirection="column"
                            alignItems="center"
                            zIndex={10}
                          >
                            <Box
                              mb={3}
                              bg={"white"}
                              borderRadius={"50%"}
                              p={1}
                              boxSize={7}
                              textAlign="center"
                              onClick={() =>
                                isAuth
                                  ? handleSendCart()
                                  : navigate("/signup", {
                                      state: `/fetchProductDetails/${id}`,
                                      replace: true,
                                    })
                              }
                            >
                              <Icon as={BsHandbag} boxSize={4} />
                            </Box>
                            <Box my={window.innerWidth > 500 ? 0 : 3}></Box>
                            <Box
                              mb={3}
                              bg={"white"}
                              borderRadius={"50%"}
                              p={1}
                              boxSize={7}
                              textAlign="center"
                              onClick={handleWishlistClick}
                            >
                              <Icon
                                as={
                                  wishlistStatus
                                    ? BsFillHeartFill
                                    : AiOutlineHeart
                                }
                                boxSize={4}
                                color={wishlistStatus ? "red" : "black"}
                              />
                            </Box>
                          </Flex>
                        </Box>
                      );
                    })}
                  </Carousel>
                  {/* </SimpleGrid> */}
                </Box>
                {productimages.length !== 0 ? (
                  <ImageModal
                    isOpen={isImgModalOpen}
                    onClose={handleImgCloseModal}
                    productImages={productimages}
                    index={imageClickedIndex}
                  />
                ) : (
                  <></>
                )}
              </Show>

              <Box
                position={"relative"}
                top={window.innerWidth > 500 ? "0%" : "-2%"}
                h="max-content"
              >
                {ProductsDetail.data && ProductsDetail.data.length !== 0 && (
                  <VStack
                    spacing={4}
                    textAlign="left"
                    w={"full"}
                    divider={
                      <StackDivider
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 5}
                      />
                    }
                    p={2}
                  >
                    <Box w="full">
                      <Heading
                        fontWeight={"600"}
                        as={"h2"}
                        color="#282c3f"
                        fontSize="28px"
                        size="lg"
                      >
                        {" "}
                        {brand}{" "}
                      </Heading>
                      <Heading
                        fontWeight={window.innerWidth > 500 ? 300 : 500}
                        as={"h2"}
                        color="#535665"
                        fontSize={window.innerWidth > 500 ? "20px" : "16px"}
                        size="lg"
                      >
                        {" "}
                        {product_name}{" "}
                      </Heading>
                      <Show breakpoint="(min-width:500px)">
                        <Box w={"full"} mt="15px">
                          <HStack
                            w="max-content"
                            cursor={"pointer"}
                            transition={".5s all"}
                            spacing={"2px"}
                            border={"1px solid #e0e0e0"}
                            borderRadius="3px"
                            padding={"2px 4px"}
                            _hover={{ borderColor: "#282c3f" }}
                          >
                            <HStack spacing={1}>
                              {Math.floor(rating) === rating ? (
                                <Text fontWeight={"bold"}>{rating}.0</Text>
                              ) : (
                                <Text fontWeight={"bold"}>{rating}</Text>
                              )}
                              <Text color="#72bfbc">&#9733;</Text>
                            </HStack>
                            <HStack spacing={1}>
                              <Text color="#e0e0e0" fontWeight={"bold"}>
                                |
                              </Text>
                              <Text fontWeight={300} color="#535766">
                                {" "}
                                {count} Ratings
                              </Text>
                            </HStack>
                          </HStack>
                        </Box>
                      </Show>
                      <Box mt="15px">
                        <Button
                          onClick={handleOpenTryOn}
                          color="#fff"
                          borderRadius="5px"
                          p="20px 35px"
                          leftIcon={<GiClothes />}
                          bgGradient={
                            "linear(to-r, red.500, red.700)"
                          }
                          variant="solid"

                          transition="all 0.3s ease-in-out"
                          _hover={{
                            bgGradient:
                              window.innerWidth < 500
                                ? "linear(to-r, gray.800, black)"
                                : "linear(to-r, red.400, red.600)",
                            transform: "scale(1.05)",
                            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
                          }}
                          _active={{
                            transform: "scale(0.98)",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          VIRTUAL TRY ON
                        </Button>
                      </Box>
                      {productImages && productImages.length > 0 && (
                        <VirtualTryOn
                          isOpen={isTryOnOpen}
                          onClose={() => setIsTryOnOpen(false)}
                          productImageUrl={productImages[0].image_url}
                          userId={userData.id}
                        />
                      )}
                    </Box>
                    {/* .................... */}

                    <VStack align="flex-start" w="full" spacing={"20px"}>
                      <VStack align="flex-start" w="full">
                        <HStack spacing={2}>
                          <Heading
                            fontWeight={"600"}
                            as={"h2"}
                            color="#282c3f"
                            fontSize="20px"
                            size="lg"
                          >
                            {" "}
                            ₹{discountedPrice}{" "}
                          </Heading>
                          <HStack spacing={1}>
                            <Heading
                              fontWeight={300}
                              as={"h2"}
                              color="#535665"
                              fontSize={window.innerWidth > 500 ? "20px" : "16px"}
                              size="lg"
                            >
                              {" "}
                              MRP
                            </Heading>
                            <Heading
                              fontWeight={300}
                              as={"h2"}
                              color="#535665"
                              fontSize={window.innerWidth > 500 ? "20px" : "16px"}
                              size="lg"
                              textDecoration={"line-through"}
                            >
                              {" "}
                              ₹{productPrice}{" "}
                            </Heading>
                          </HStack>
                          <Heading
                            fontWeight={"600"}
                            as={"h2"}
                            color="red"
                            fontSize={window.innerWidth > 500 ? "20px" : "16px"}
                            size="lg"
                          >
                            {" "}
                            ({discountPer}% OFF){" "}
                          </Heading>
                        </HStack>
                        <Text
                          color={"#03a685"}
                          fontSize="12px"
                          fontWeight={"bold"}
                        >
                          inclusive of all taxes
                        </Text>
                      </VStack>

                      {/*  */}
                      {/*  */}
                      {/*  */}
                      {/*  */}
                      {/*  */}
                      {/*  */}

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {productColor?.map((el, i) => {
                          return (
                            <VStack key={i}>
                              <Text fontSize={"10px"} as={"b"}>
                                {el.productcolor.product_color}
                              </Text>
                              <Box
                                w={"60px"}
                                pointerEvents={el.qty === "0" ? "none" : " "}
                                boxShadow={"base"}
                                rounded={"sm"}
                                key={i + toString(i)}
                                onClick={() =>
                                  navigate(
                                    `../fetchProductDetails/${el.product_id}`
                                  )
                                }
                                bg={`${
                                  el.productcolor.id === el.product_id
                                    ? "white"
                                    : "#D9D9D9"
                                }`}
                                color={`${
                                  el.productcolor.id === el.product_id
                                    ? "white"
                                    : "black"
                                }`}
                                border={`1px solid ${
                                  el.productcolor.id === el.product_id
                                    ? "black"
                                    : "#b8b8b8cb"
                                }`}
                                cursor={"pointer"}
                                position={"relative"}
                                transition=".5s  all"
                                _hover={{
                                  borderColor: "black",
                                }}
                              >
                                <Center
                                  fontSize={"14px"}
                                  fontWeight={500}
                                  // p={"5px"}
                                >
                                  <Image src={el.productimage?.image_url}></Image>
                                </Center>
                                {/* {el.qty === '0' ?
                                        <Box bg={'#7b7b7b1c'} position={'absolute'} top={'1px'} width={'100%'} height={'100%'}>
                                          <Text color={'#9a9a9ac9'} w={'100%'} >______</Text>
                                        </Box> : <></> } */}
                              </Box>
                            </VStack>
                          );
                        })}
                      </div>

                      <StackDivider
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      {/*  */}
                      {/*  */}
                      {/*  */}
                      {/*  */}
                      {/*  */}
                      {variations.length > 0 ? (
                        <>
                          <HStack
                            fontSize="14px"
                            justify={"space-between"}
                            w={window.innerWidth > 500 ? "200px" : "100%"}
                          >
                            <Text fontWeight={"bold"} color="#282c3f">
                              SELECT SIZE
                            </Text>
                            <Link
                              fontWeight={500}
                              color="red"
                              onClick={() => setIsSizeChartModalOpen(true)}
                            >
                              SIZE CHART &#62;
                            </Link>
                            <SizeChartModal
                              isOpen={isSizeChartModalOpen}
                              onClose={() => setIsSizeChartModalOpen(false)}
                              // sizechar_image_url={
                              //   currentProduct.sizechar_image_url
                              // }
                              sizechar_image_url={
                                ProductsDetail.data.sizechar_image_url
                              }
                            ></SizeChartModal>
                          </HStack>

                          <SimpleGrid columns={7} spacing={"10px"}>
                            {variations?.map((el, i) => {
                              return (
                                <>
                                  {window.innerWidth < 500 ? (
                                    <Box
                                      w={42}
                                      h={42}
                                      pointerEvents={
                                        el.qty === "0" ? "none" : " "
                                      }
                                      boxShadow={"base"}
                                      rounded={"md"}
                                      key={i + toString(i)}
                                      onClick={() => setsize(el.id, el)}
                                      // borderRadius={"full"}
                                      bg={
                                        el.qty === "0"
                                          ? "#F3F3F3"
                                          : `${
                                              sizeRef === el.id
                                                ? "black"
                                                : "white"
                                            }`
                                      }
                                      color={
                                        el.qty === "0"
                                          ? "#A2A2A2"
                                          : `${
                                              sizeRef === el.id
                                                ? "white"
                                                : "black"
                                            }`
                                      }
                                      border={
                                        el.qty === "0"
                                          ? `1px solid #F3F3F3`
                                          : `1px solid ${
                                              sizeRef === el.id
                                                ? "black"
                                                : "#b8b8b8cb"
                                            }`
                                      }
                                      cursor={"pointer"}
                                      position={"relative"}
                                      transition=".5s  all"
                                      _hover={{
                                        borderColor: `${
                                          el.qty === "0" ? "#F3F3F3" : "black"
                                        }`,
                                      }}
                                    >
                                      <Center
                                        fontSize={"14px"}
                                        fontWeight={500}
                                        p={"10px"}
                                      >
                                        {el.variation}
                                      </Center>
                                      {el.qty === "0" ? (
                                        <Box
                                          bg={"#7b7b7b1c"}
                                          position={"absolute"}
                                          top={"1px"}
                                          width={"100%"}
                                          height={"100%"}
                                        >
                                          {/* <TagCloseButton w={'100%'} /> */}
                                          <Text
                                            // color={"#9a9a9ac9"}
                                            color={"black"}
                                            w={"150%"}
                                            style={{
                                              position: "absolute",
                                              left: "-35%",
                                              transform: "rotate(-45deg)",
                                              textAlign: "center",
                                            }}
                                          >
                                            ______
                                          </Text>
                                        </Box>
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  ) : (
                                    <Box
                                      w={42}
                                      h={42}
                                      key={i + toString(i)}
                                      onClick={() => setsize(el.id, el)}
                                      pointerEvents={
                                        el.qty === "0" ? "none" : " "
                                      }
                                      // borderRadius={"full"}

                                      bg={"white"}
                                      color={"black"}
                                      border={`1px solid ${
                                        sizeRef === el.id
                                          ? "#ff3f6c"
                                          : "#b8b8b8cb"
                                      }`}
                                      cursor={"pointer"}
                                      transition=".5s  all"
                                      position={"relative"}
                                      _hover={{
                                        borderColor: `${
                                          el.qty === "0" ? "#F3F3F3" : "black"
                                        }`,
                                      }}
                                    >
                                      <Center
                                        fontSize={"14px"}
                                        fontWeight={500}
                                        p={"10px"}
                                      >
                                        {el.variation}
                                      </Center>
                                      {el.qty === "0" ? (
                                        <Box
                                          bg={"#7b7b7b1c"}
                                          position={"absolute"}
                                          top={"1px"}
                                          width={"100%"}
                                          height={"100%"}
                                        >
                                          {/* <TagCloseButton w={'100%'} /> */}

                                          <Text
                                            // color={"#9a9a9ac9"}
                                            color={"black"}
                                            w={"150%"}
                                            style={{
                                              position: "absolute",
                                              left: "-37.5%",
                                              transform: "rotate(-45deg)",
                                              textAlign: "center",
                                            }}
                                          >
                                            ______
                                          </Text>
                                        </Box>
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  )}
                                </>
                              );
                            })}
                          </SimpleGrid>
                        </>
                      ) : null}
                      <HStack>
                        <Button
                          onClick={() =>
                            isAuth
                              ? handleSendCart()
                              : navigate("/signup", {
                                  state: `/fetchProductDetails/${id}`,
                                  replace: true,
                                })
                          }
                          color={"#fff"}
                          borderRadius={3}
                          p="22px 30px"
                          leftIcon={<BsHandbag />}
                          bg={window.innerWidth < 500 ? "black" : "red"}
                          variant={"solid"}
                          _hover={{
                            bg: isLargerThan500 ? "red.200" : "black",
                            color: "white",
                          }}
                        >
                          ADD TO BAG
                        </Button>
                        <Button
                          onClick={handleWishlistClick}
                          // onClick={()=>isAuth?handleSendWishlist():navigate("/login",{state:`/fetchProductDetails/${id}`, replace:true}) }
                          color={"#000"}
                          borderRadius={3}
                          p="22px 30px"
                          leftIcon={
                            <Icon
                              as={AiOutlineHeart}
                              boxSize={5}
                              color={"black"}
                            />
                          }
                          colorScheme="cyan"
                          variant={"outline"}
                        >
                          WISHLIST
                        </Button>
                      </HStack>
                    </VStack>
                    {/* eslint-disable-next-line */}
                    {couponCode != null && couponCode != "" ? (
                      <VStack
                        align="flex-start"
                        w="full"
                        spacing={"10px"}
                        py="5px"
                      >
                        <HStack>
                          <Text fontWeight={"bold"} color="#282c3f">
                            BEST OFFERS
                          </Text>
                          <Icon as={BiPurchaseTag} fontSize="xl" />
                        </HStack>

                        <VStack
                          align="flex-start"
                          w="full"
                          spacing={"5px"}
                          py="5px"
                        >
                          {window.innerWidth < 500 ? (
                            <Box
                              borderWidth="1px"
                              borderRadius="lg"
                              p={4}
                              w={"100%"}
                              shadow="md"
                              bg="white"
                              display="flex"
                            >
                              <Text mr={2}>{couponText}</Text>
                              <Box
                                bg="#EEF3F6"
                                borderRadius={5}
                                width={"100px"}
                                p={3}
                              >
                                <Text
                                  justifyContent={"center"}
                                  display={"flex"}
                                  fontSize="xs"
                                >
                                  Use Code
                                </Text>
                                <Text
                                  justifyContent={"center"}
                                  display={"grid"}
                                  fontSize="xs"
                                >
                                  {couponCode}
                                </Text>
                                <Text
                                  justifyContent={"center"}
                                  display={"grid"}
                                  fontSize="xs"
                                  as="u"
                                  onClick={() => {
                                    navigate("/terms");
                                  }}
                                >
                                  T&C
                                </Text>
                              </Box>
                            </Box>
                          ) : (
                            <Text>{couponText}</Text>
                          )}

                          <Button
                            mt={2}
                            colorScheme="red"
                            size={"xs"}
                            variant={"link"}
                            onClick={() => setIsCoupansModalOpen(true)}
                          >
                            Other Offers
                          </Button>
                          <ViewCoupanModal
                            isOpen={isCoupansModalOpen}
                            price={productPrice}
                            discountedprice={discountedPrice}
                            onClose={handleCoupansModalClose}
                          ></ViewCoupanModal>
                        </VStack>
                      </VStack>
                    ) : (
                      <></>
                    )}

                    <VStack align="flex-start" w="full" spacing={"20px"}>
                      <HStack>
                        <Text fontWeight={"bold"} color="#282c3f">
                          DELIVERY OPTIONS
                        </Text>
                        <Icon as={BsTruck} fontSize="xl" />
                      </HStack>

                      <Box w={window.innerWidth > 500 ? " " : "100%"}>
                        <InputGroup size="md">
                          <Input
                            focusBorderColor="#bdbdbd"
                            placeholder="Enter pincode"
                            value={inputPincode}
                            onChange={(e) => setInputPincode(e.target.value)}
                            maxLength={6}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handlePincodeCheck();
                              }
                            }}
                            disabled={
                              pincodeAvailbility.status === 1 ||
                              pincodeAvailbility.status === 0
                            }
                          />
                          <InputRightElement width="4.5rem">
                            <Button
                              variant={"unstyled"}
                              color="red.600"
                              onClick={handlePincodeCheck}
                            >
                              {isPincodeVerified ? "Change" : "Check"}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <Text
                          color={
                            pincodeAvailbility.status === 0 ? "red" : "green"
                          }
                          fontSize="14px"
                        >
                          {pincodeAvailbility.message}
                        </Text>
                      </Box>

                      <VStack align="flex-start" w="full" spacing={"10px"}>
                        <Text color={"#282c3f"} fontSize="14px" as={"b"}>
                          {pincodeAvailbility.data}
                        </Text>
                        <Text color={"#282c3f"} fontSize="14px">
                          100% Original Products
                        </Text>
                        <Text color={"#282c3f"} fontSize="14px">
                          Pay on delivery might be available
                        </Text>
                        {/* eslint-disable-next-line */}
                        {ProductsDetail.data.is_return == 1 ? (
                          <Text color={"#282c3f"} fontSize="14px">
                            Easy returns within {ProductsDetail.data.return_days}{" "}
                            days
                          </Text>
                        ) : (
                          <></>
                        )}

                        {/* <Text color={"#282c3f"} fontSize="14px">Try & Buy might be available</Text> */}
                      </VStack>

                      <StackDivider
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      <VStack align="flex-start" w="full">
                        <Show breakpoint="(min-width: 500px)">
                          <Text fontWeight={"bold"} color="#282c3f">
                            PRODUCT DETAILS
                          </Text>
                          <Text color={"#282c3f"} fontSize="14px">
                            {/* {ProductsDetail.data.description}
                            {"."} */}
                            {description}
                          </Text>

                          {ProductsDetail.data.size_description ? (
                            <>
                              <Text
                                fontWeight={"bold"}
                                color="#282c3f"
                                fontSize={"14px"}
                              >
                                Size & Fit
                              </Text>
                              <Text color={"#282c3f"} fontSize="14px">
                                {/* {ProductsDetail.data.size_description}
                                {"."} */}
                                {size_description}
                              </Text>
                            </>
                          ) : (
                            ""
                          )}

                          {ProductsDetail.data.material_description ? (
                            <>
                              <Text
                                fontWeight={"bold"}
                                color="#282c3f"
                                fontSize={"14px"}
                              >
                                Material & Care
                              </Text>
                              <Text color={"#282c3f"} fontSize="14px">
                                {processedDescription}
                              </Text>
                            </>
                          ) : (
                            ""
                          )}
                        </Show>
                        <Show breakpoint="(max-width: 500px)">
                          <Box w={"100%"}>
                            <HStack justifyContent={"space-around"}>
                              <Box justifyItems={"center"} display={"grid"}>
                                <Image
                                  mb={2}
                                  src={
                                    "https://kk-logo.boxinallsoftech.com/happy-client_green.png"
                                  }
                                  width={10}
                                ></Image>
                                <Text fontWeight={"bold"} fontSize={"10px"}>
                                  1 Million+
                                </Text>
                                <Text fontWeight={"bold"} fontSize={"10px"}>
                                  Happy customers
                                </Text>
                              </Box>
                              <Box justifyItems={"center"} display={"grid"}>
                                <Image
                                  mb={2}
                                  src={
                                    "https://kk-logo.boxinallsoftech.com/original_products_green.png"
                                  }
                                  width={10}
                                ></Image>
                                <Text fontWeight={"bold"} fontSize={"10px"}>
                                  Genuine
                                </Text>
                                <Text fontWeight={"bold"} fontSize={"10px"}>
                                  products
                                </Text>
                              </Box>
                              <Box justifyItems={"center"} display={"grid"}>
                                <Image
                                  mb={2}
                                  src={
                                    "https://kk-logo.boxinallsoftech.com/best_quality_green.png"
                                  }
                                  width={10}
                                ></Image>
                                <Text fontWeight={"bold"} fontSize={"10px"}>
                                  Best
                                </Text>
                                <Text fontWeight={"bold"} fontSize={"10px"}>
                                  Quality
                                </Text>
                              </Box>
                            </HStack>
                          </Box>
                          <StackDivider
                            borderColor="gray.200"
                            borderWidth={window.innerWidth > 500 ? "" : 3}
                            my={5}
                          />
                          <Accordion allowToggle w={"100%"}>
                            <AccordionItem>
                              <h2>
                                <AccordionButton paddingInlineStart={0}>
                                  <Box as="span" flex="1" textAlign="left">
                                    <Text fontWeight={"bold"} color="#282c3f">
                                      PRODUCT DETAILS
                                    </Text>
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                <Text color={"#282c3f"} fontSize="14px">
                                  {/* {ProductsDetail.data.description}
                                  {"."} */}
                                  {description}
                                </Text>

                                {ProductsDetail.data.size_description ? (
                                  <>
                                    <Text
                                      fontWeight={"bold"}
                                      color="#282c3f"
                                      fontSize={"14px"}
                                      marginY={2}
                                    >
                                      Size & Fit
                                    </Text>
                                    <Text color={"#282c3f"} fontSize="14px">
                                      {/* {ProductsDetail.data.size_description}
                                      {"."} */}
                                      {size_description}
                                    </Text>
                                  </>
                                ) : (
                                  ""
                                )}
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                          {/*  */}
                          {/*  */}
                          {/*  */}
                          {/*  */}

                          <Accordion allowToggle w={"100%"}>
                            <AccordionItem>
                              <h2>
                                <AccordionButton paddingInlineStart={0}>
                                  <Box as="span" flex="1" textAlign="left">
                                    <Text fontWeight={"bold"} color="#282c3f">
                                      MATERIAL & CARE
                                    </Text>
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                {ProductsDetail.data.material_description ? (
                                  <>
                                    <Text color={"#282c3f"} fontSize="14px">
                                      {processedDescription}
                                    </Text>
                                  </>
                                ) : (
                                  ""
                                )}
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                          <Accordion allowToggle w={"100%"}>
                            {/*  */}
                            {/*  */}
                            {/*  */}
                            {/*  */}
                            <AccordionItem>
                              <h2>
                                <AccordionButton paddingInlineStart={0}>
                                  <Box as="span" flex="1" textAlign="left">
                                    <Text fontWeight={"bold"} color="#282c3f">
                                      RETURN
                                    </Text>
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                {ProductsDetail.data.is_return === 1 ? (
                                  <Text>
                                    Return can be done in{" "}
                                    {ProductsDetail.data.return_days} days
                                  </Text>
                                ) : (
                                  <Text>No Return</Text>
                                )}
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                          <Accordion allowToggle w={"100%"}>
                            <AccordionItem>
                              <h2>
                                <AccordionButton paddingInlineStart={0}>
                                  <Box as="span" flex="1" textAlign="left">
                                    <Text fontWeight={"bold"} color="#282c3f">
                                      CONTACT INFORMATION
                                    </Text>
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                <Box>
                                  <Text
                                    fontSize="14px"
                                    color="blue.500"
                                    fontWeight="bold"
                                  >
                                    +91 9875982194
                                  </Text>
                                  <Text
                                    fontSize="14px"
                                    color="blue.500"
                                    fontWeight="bold"
                                  >
                                    customercare@98degreenorth.com
                                  </Text>
                                  <Link
                                    fontSize="14px"
                                    onClick={() => {
                                      navigate("/contact");
                                    }}
                                  >
                                    Contact us for more information
                                  </Link>
                                </Box>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        </Show>
                      </VStack>
                      <VStack align="flex-start" w="full">
                        <Show breakpoint="(min-width: 500px)">
                          <Text fontWeight={"bold"} color="#282c3f">
                            CONTACT SELLER
                          </Text>

                          <Link
                            fontSize="14px"
                            color="blue.500"
                            fontWeight="bold"
                          >
                            +91 9875982194
                          </Link>
                          <Link
                            fontSize="14px"
                            color="blue.500"
                            fontWeight="bold"
                          >
                            customercare@98degreenorth.com
                          </Link>
                          <Link
                            fontSize="14px"
                            onClick={() => {
                              navigate("/contact");
                            }}
                          >
                            Contact us for more information
                          </Link>
                        </Show>
                      </VStack>
                      <VStack align="flex-start" w="full" spacing={"20px"}>
                        <VStack align="flex-start" w="full">
                          <Text fontWeight={"bold"} color="#282c3f">
                            REVIEWS &#9733;
                          </Text>
                          <VStack
                            align="flex-start"
                            w="full"
                            spacing={"5px"}
                            maxH="200px"
                            overflowY="auto"
                            divider={<StackDivider borderColor="gray.200" />}
                          >
                            <div>
                              <HStack>
                                {/* eslint-disable-next-line */}
                                <Heading fontWeight={400}>
                                  {Array.isArray(ProductsDetail.data.rattings) &&
                                  ProductsDetail.data.rattings.length !== 0
                                    ? ProductsDetail.data.rattings[0].avg_rating
                                    : 0}
                                </Heading>
                                <Icon
                                  as={StarIcon}
                                  w={3}
                                  h={2}
                                  ml={1}
                                  boxSize={5}
                                  cursor="pointer"
                                  color={"red"}
                                />
                              </HStack>
                              {/* eslint-disable-next-line */}
                              <Text fontSize={"13px"} mb={4}>
                                {Array.isArray(ProductsDetail.data.rattings) &&
                                ProductsDetail.data.rattings.length !== 0
                                  ? ProductsDetail.data.rattings[0].count
                                  : 0}{" "}
                                Verified Buyers
                              </Text>
                            </div>
                            {
                              Array.isArray(ProductsDetail.ratings) &&
                                ProductsDetail.ratings.map((item, i) => (
                                  <Box key={i}>
                                    <Badge
                                      colorScheme="red"
                                      variant="solid"
                                      display="inline-flex"
                                      alignItems="center"
                                    >
                                      <span>{item.ratting}</span>
                                      <Icon
                                        key={i}
                                        as={StarIcon}
                                        w={3}
                                        h={2}
                                        ml={1}
                                        cursor="pointer"
                                      />
                                    </Badge>
                                    <Text fontSize={"13px"}>{item.comment}</Text>
                                    <Text fontSize={"11px"} color={"gray.500"}>
                                      {item.date}
                                    </Text>
                                  </Box>
                                ))
                              // ProductsDetail.ratings.forEach(element => {
                              //   <Box>
                              //   <Badge
                              //     colorScheme="pink"
                              //     variant="solid"
                              //     display="inline-flex"
                              //     alignItems="center"
                              //   >
                              //     <span>{element.ratting}</span>
                              //     <Icon
                              //       as={StarIcon}
                              //       w={3}
                              //       h={2}
                              //       ml={1}
                              //       cursor="pointer"
                              //     />
                              //   </Badge>
                              //   <Text fontSize={"13px"}>
                              // {element.comment}
                              //   </Text>
                              //   <Text fontSize={"11px"} color={"gray.500"}>
                              //     {element.date}
                              //   </Text>
                              // </Box>
                              // })
                            }
                            {/*
                            <Box>
                              <Badge
                                colorScheme="pink"
                                variant="solid"
                                display="inline-flex"
                                alignItems="center"
                              >
                                <span>4</span>
                                <Icon
                                  as={StarIcon}
                                  w={3}
                                  h={2}
                                  ml={1}
                                  cursor="pointer"
                                />
                              </Badge>
                              <Text fontSize={"13px"}>
                                Overall its a good product, just that the length and
                                thickness was lesser than expected but its very
                                comfortable and is a good quality material.
                              </Text>
                              <Text fontSize={"11px"} color={"gray.500"}>
                                12 December
                              </Text>
                            </Box>
                            <Box>
                              <Badge
                                colorScheme="pink"
                                variant="solid"
                                display="inline-flex"
                                alignItems="center"
                              >
                                <span>4</span>
                                <Icon
                                  as={StarIcon}
                                  w={3}
                                  h={2}
                                  ml={1}
                                  cursor="pointer"
                                />
                              </Badge>
                              <Text fontSize={"13px"}>
                                Overall its a good product, just that the length and
                                thickness was lesser than expected but its very
                                comfortable and is a good quality material.
                              </Text>
                              <Text fontSize={"11px"} color={"gray.500"}>
                                12 December
                              </Text>
                            </Box> */}
                          </VStack>
                        </VStack>
                      </VStack>
                    </VStack>
                  </VStack>
                )}
              </Box>
            </Grid>
          </Box>
        )}
        {/* ....................... */}

        {relatedProduct.length > 0 ? (
          <>
            <Box
              w={"full"}
              // p={"50px 30px"}
            >
              <Text
                textAlign="left"
                my={8}
                ml={2}
                fontWeight={"bold"}
                color="#282c3f"
              >
                SIMILAR PRODUCTS
              </Text>
              <SimpleGrid
                // columns={similarProducts.length >= 6 ? 6 : similarProducts.length <= 3 ? 4 : similarProducts.length}
                columns={{ base: 2, md: 4, lg: 4, sm: 2 }}
                spacingX="2px"
                spacingY="3px"
                w="100%"
              >
                {relatedProduct?.map((el) => {
                  return (
                    <Box key={el.id}>
                      <RelatedProduct {...el} />
                    </Box>
                  );
                })}
              </SimpleGrid>
            </Box>
          </>
        ) : null}

        {/* ....................... */}
        <Show breakpoint="(min-width: 500px)">
          <Footer />
        </Show>
      </>
    );
  };

  export default SingleProduct;
