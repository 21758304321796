import React from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';

const CountdownSpinner = ({ countdown }) => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="150px"
      height="150px"
      borderRadius="full"
      bg="rgba(0, 0, 0, 0.5)"
      boxShadow="0 5px 15px rgba(0, 0, 0, 0.3)"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        color="pink.500"
        size="xl"
        position="absolute"
      />
      <Text
        position="absolute"
        fontSize="3xl"
        fontWeight="bold"
        color="white"
      >
        {countdown > 0 ? countdown : 'Go!'}
      </Text>
    </Box>
  );
};

export default CountdownSpinner;
